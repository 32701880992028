import { Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { App } from '@capacitor/app';
import { Router } from '@angular/router';
import { FunctionsService } from './services/functions.service';
import { AuthenticationService } from './services/authentication.service';

declare var Pushy: any;

@Component( {
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: [ 'app.component.scss' ],
} )
export class AppComponent implements OnInit
{
    authentication: AuthenticationService;

    constructor(
        private authService: AuthenticationService,
        private router: Router,
        private functionsService: FunctionsService,
        private platform: Platform
    )
    {
        this.authentication = authService;

        this.platform.ready().then( ( readySource ) =>
        {
            // Wait for deviceready
            document.addEventListener( 'deviceready', this.initializePushy.bind( null, authService, router, functionsService ) );
        } );
    }

    ngOnInit()
    {
        App.addListener( 'backButton', this.handleBackButton.bind( null, this.router ) );

        /*App.addListener( 'backButton', ( { canGoBack } ) => {
            console.log( "Route:", this.router.routerState.snapshot.url );
            if( canGoBack )
            {
                window.history.back();
            }
            else
            {
                App.minimizeApp();
            }
        } );*/
    }

    handleBackButton( router: Router, status: any )
    {
        if ( Pushy )
        {
            // Clear iOS app badge number
            Pushy.clearBadge();
        }

        const canGoBack = status.canGoBack && ( router.routerState.snapshot.url.toLowerCase() !== '/login' );
        if ( canGoBack )
        {
            window.history.back();
        }
        else
        {
            App.minimizeApp();
        }
    }

    initializePushy( authService: AuthenticationService, router: Router, functionsService: FunctionsService )
    {
        // Start the Pushy service
        Pushy.listen();

        // Register the user for push notifications
        Pushy.register( function ( err, deviceToken )
        {
            // Handle registration errors
            if ( err )
            {
                //functionsService.showAlert( 'Anforderung fehlgeschlagen', err );
                return;
            }

            // Store device token in authentication service.
            // It will be transmitted to the backend on login.
            authService.pushyDeviceToken = deviceToken;
        } );

        // Enable in-app notification banners (iOS 10+)
        Pushy.toggleInAppBanner( true );

        // Listen for push notifications
        Pushy.setNotificationListener( function ( data )
        {
            // Display an alert with the "message" payload value
            functionsService.showAlert( 'Hinweis', data.message );

            // Clear iOS app badge number
            Pushy.clearBadge();
        } );

        // Listen for notification click
        Pushy.setNotificationClickListener( function ( data )
        {
            // Display an alert with the "message" payload value
            functionsService.showAlert( 'Hinweis', data.message );

            // Clear iOS app badge number
            Pushy.clearBadge();

            router.navigateByUrl( '/', { replaceUrl: true } );
        } );

        // Clear iOS app badge number
        Pushy.clearBadge();
    }
}
