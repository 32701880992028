import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class FunctionsService
{
    constructor(
        private alertController: AlertController
    )
    {

    }

    // Show an alert
    async showAlert( header, message )
    {
        const isObject = message === Object( message );
        let msg = message;
        if ( isObject )
        {
            msg = '<ul>';
            for ( let i in message )
            {
                msg += '<li>' + message[ i ] + '</li>';
            }
            msg += '</ul>';
        }
        const alert = await this.alertController.create( {
            header: header,
            message: msg,
            buttons: [ 'OK' ]
        } );
        await alert.present();
    }
}
