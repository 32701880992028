import { AuthGuard } from './guards/auth.guard';
import { LogoutGuard } from './guards/logout.guard';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    // ---- Private
    {
        path: '',
        loadChildren: () => import('./pages/private/home/home.module').then( m => m.HomePageModule),
        canActivate: [ AuthGuard ]
    },
    {
        path: 'new',
        loadChildren: () => import('./pages/private/session/new/new.module').then( m => m.NewPageModule),
        canActivate: [ AuthGuard ]
    },
    {
        path: 'extend/:hash',
        loadChildren: () => import('./pages/private/session/extend/extend.module').then( m => m.ExtendPageModule),
        canActivate: [ AuthGuard ]
    },
    {
        path: 'settings',
        loadChildren: () => import('./pages/private/settings/settings.module').then( m => m.SettingsPageModule),
        canActivate: [ AuthGuard ]
    },
    {
      path: 'score',
      loadChildren: () => import('./pages/private/score/score.module').then( m => m.ScorePageModule),
      canActivate: [ AuthGuard ]
    },
    // ---- Public
    {
        path: 'login',
        loadChildren: () => import('./pages/public/login/login.module').then( m => m.LoginPageModule)
    },
    {
        path: 'logout',
        loadChildren: () => import('./pages/public/login/login.module').then( m => m.LoginPageModule),
        canActivate: [ LogoutGuard ]
    },
    {
        path: 'register',
        loadChildren: () => import('./pages/public/register/register.module').then( m => m.RegisterPageModule)
    },
    {
        path: 'privacy-policy',
        loadChildren: () => import('./pages/public/legal/privacy-policy/privacy-policy.module').then( m => m.PrivacyPolicyPageModule)
    },
    {
        path: '**',
        redirectTo: 'login',
        pathMatch: 'full'
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
