import { AuthenticationService } from '../services/authentication.service';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
//import { NavController } from '@ionic/angular';
import { filter, map, take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class LogoutGuard implements CanActivate
{
    constructor(
        private authService: AuthenticationService,
        private router: Router,
        //private nav: NavController
    )
    {

    }

    canActivate(): Observable< boolean >
    {
        return (
            this.authService.isAuthenticated.pipe(
                filter( val => val !== null ),
                take( 1 ),
                map( isAuthenticated => {
                    if ( isAuthenticated )
                    {
                        this.authService.logout();
                        //this.nav.navigateRoot( '/login' )
                        this.router.navigateByUrl( '/login', { replaceUrl: true } );
                        return true;
                    }
                    else
                    {
                        return false;
                    }
                } )
            )
        );
    }

}
