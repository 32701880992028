import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, tap, switchMap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { TokenStorageService } from './token-storage.service';
import { environment } from '../../environments/environment';

const httpOptions =
{
    headers: new HttpHeaders( { 'Content-Type': 'application/json' } )
};

@Injectable( {
    providedIn: 'root'
} )
export class AuthenticationService
{
    isAuthenticated: BehaviorSubject< boolean > = new BehaviorSubject< boolean >( null );
    pushyDeviceToken: string = '';

    constructor(
        private http: HttpClient,
        private tokenStorage: TokenStorageService
    )
    {
        this.isAuthenticated.next( false );
        this.loadToken();
    }

    /**
     * Load authentication token
     */
    async loadToken()
    {
        const token = this.tokenStorage.getToken();
        if ( token )
        {
            this.isAuthenticated.next( true );
        }
        else
        {
            this.isAuthenticated.next( false );
        }
    }

    /**
     * Attempt to login
     */
    login( credentials: { email, password } ): Observable< any >
    {
        const data: any = {
            email: credentials.email,
            password: credentials.password,
            deviceToken: this.pushyDeviceToken
        }
        return this.http.post( environment.apiUrl + 'login/index', data, httpOptions ).pipe(
            map( ( data: any ) => {
                this.tokenStorage.saveToken( data.accessToken );
            } ),
            tap( _ => {
                this.isAuthenticated.next( true );
            } )
        );
    }

    /**
     * Register an account
     */
    register( credentials: { email, password, password_confirm } ): Observable< any >
    {
        return this.http.post( environment.apiUrl + 'register/create', credentials, httpOptions ).pipe(
            map( ( data: any ) => {
                this.tokenStorage.saveToken( data.accessToken );
            } ),
            tap( _ => {
                this.isAuthenticated.next( true );
            } )
        );
    }

    /**
     * Logout
     */
    logout()
    {
        this.tokenStorage.signOut();
        this.isAuthenticated.next( false );
    }

    /**
     * Update authentication token
     */
    update( token: string )
    {
        this.tokenStorage.saveToken( token );
    }

    /**
     * Activate account
     */
    activateAccountRequest( hash: string ): Observable< any >
    {
        return this.http.put( environment.apiUrl + 'register/update/' + hash,
            httpOptions );
    }

    /**
     * Reset password
     */
    resetPasswordRequest( credentials: { email } ): Observable< any >
    {
        return this.http.post( environment.apiUrl + 'login/reset',
            credentials,
            httpOptions );
    }

    /**
     * Update password
     */
    updatePasswordRequest( credentials: { hash, password, password_confirm } ): Observable< any >
    {
        return this.http.post( environment.apiUrl + 'login/update',
            credentials,
            httpOptions );
    }

    /**
     * Update account password
     */
    updateAccountPassword( credentials: { password, new_password, new_password_confirm } ): Observable< any >
    {
        return this.http.put( environment.apiUrl + 'users/password',
            credentials,
            httpOptions );
    }

    /**
     * Delete account
     */
    deleteAccount( credentials: { password } ): Observable< any >
    {
        return this.http.post( environment.apiUrl + 'users/delete_account',
            credentials,
            httpOptions );
    }
}
